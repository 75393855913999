import React from 'react';
import styled from 'styled-components';
import { connect, ConnectedComponent } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Prompt, RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { Form as UIForm, Segment, Button, Message, Icon } from 'semantic-ui-react';
import { withLocalize, TranslateFunction } from 'react-localize-redux';
import qs from 'qs';

import Error from './Error';
import { format } from '@/utils/datetime';
import { ApplicationState } from '@/store';

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const Dates = styled.div`
    margin-left: 20px;
    color: ${(props) => props.theme.gray500};
`;

const ButtonOnTheRight = styled(Button)`
    &&& {
        margin-left: auto;
    }
`;

function hasSomeParentTheClass(element: any, classname: string): any {
    if (element && element.className && element.className.split(' ').indexOf(classname) >= 0)
        return true;
    return element && element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
}

// TODO. Write correct complete TS typings

// Fix later
type Action = Function;

export interface FormProps<FormValues = any>
    extends RouteComponentProps<any>,
        InjectedFormProps<any, any, any>,
        ConnectedComponent<any, any> {
    name: string;
    validate?: Function;
    action?: Action;
    showErrors: boolean;
    showActionBar: boolean;
    attachActionBar: boolean | 'top' | 'bottom' | undefined;
    submitButtonText?: string;
    submitDisabled?: boolean;
    confirmOnLeave?: boolean;
    children: (formProps: any) => React.ReactNode;
    onCancel: () => void;
    showSuccessMesage?: boolean;
    basic?: boolean;
    formValues: FormValues;
    // handleSubmit: (action: Action) => void;
    translate: TranslateFunction;
    correlationIds: ApplicationState['correlationId']['entity']
}

export interface Props extends FormProps {}

interface OwnProps {}

const getUpperLevelPathname = (pathname: string) => {
    return pathname.split('/').slice(0, -1).join('/') || '/';
};

class Form extends React.Component<Props> {
    static defaultProps = {
        formValues: {},
        showActionBar: true,
        showSuccessMesage: true,
        showErrors: true,
        attachActionBar: 'bottom',
    };

    handleCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else if (
            !hasSomeParentTheClass(document.getElementById(`form-${this.props.form}`), 'modal')
        ) {
            this.props.dispatch(push(getUpperLevelPathname(this.props.location.pathname)));
        }
    };

    handleSubmit = (e: React.FormEvent, data: any) => {
        e.stopPropagation();
        const { action, handleSubmit } = this.props;
        // @ts-ignore
        const submit = action ? handleSubmit(action) : handleSubmit;
        submit(e);
    };

    promptMessage = (location: Location): string | boolean => {
        return this.props.location.pathname === location.pathname && this.props.location.hash === location.hash
            ? true
            : (this.props.translate('form.onLeave') as string);
    };

    render() {
        const {
            children,
            pristine,
            error,
            translate,
            submitSucceeded,
            submitting,
            showActionBar,
            showSuccessMesage,
            basic,
            formValues,
            showErrors,
            form,
            attachActionBar,
            submitDisabled,
            confirmOnLeave,
            location,
            correlationIds
        } = this.props;

        const query = qs.parse(location.search.slice(1));

        return (
            <UIForm id={`form-${form}`} data-testid={`form-${form}`} onSubmit={this.handleSubmit}>
                {children && children(this.props)}
                {showErrors && error && (
                    // @ts-ignore
                    <Error error={error} attached={!showActionBar} />
                )}
                {showSuccessMesage && submitSucceeded && pristine && (
                    <Message positive attached>
                        <Icon name="checkmark" />
                        {translate('form.submitSuccessMessage')}
                    </Message>
                )}
                {showActionBar && (
                    <Segment basic={basic} attached={attachActionBar}>
                        <Flex>
                            <Button
                                onClick={this.handleCancel}
                                type="button"
                                content={translate('form.cancel')}
                            />
                            {formValues && (
                                <Dates>
                                    {formValues.Created &&
                                        `${translate(`form.created`)}: ${format(
                                            formValues.Created,
                                            'PPp'
                                        )}`}
                                    <br />
                                    {formValues.Updated &&
                                        `${translate(`form.updated`)}: ${format(
                                            formValues.Updated,
                                            'PPp'
                                        )}`}
                                    {formValues.CreatedDate &&
                                        `${translate(`form.created`)}: ${format(
                                            formValues.CreatedDate,
                                            'PPp'
                                        )}`}
                                    <br />
                                    {formValues.UpdatedDate &&
                                        `${translate(`form.updated`)}: ${format(
                                            formValues.UpdatedDate,
                                            'PPp'
                                        )}`}
                                </Dates>
                            )}
                            <ButtonOnTheRight
                                disabled={submitDisabled || submitting}
                                primary
                                type="submit"
                                loading={submitting}
                                floated="right"
                                content={this.props.submitButtonText || translate('form.save')}
                            />
                        </Flex>
                    </Segment>
                )}

                <Prompt
                    when={confirmOnLeave && !pristine && !submitSucceeded && !query.afterSuccess}
                    // @ts-ignore
                    message={this.promptMessage}
                />
            </UIForm>
        );
    }
}

function mapStateToProps(state: ApplicationState, props: any) {
    return {
        correlationIds: state.correlationId.entity,
        form: props.name,
        formValues: getFormValues(props.name)(state),
        initialValues: props.initialValues,
        validate: props.validate,
        enableReinitialize: props.enableReinitialize,
        keepDirtyOnReinitialize:
            typeof props.keepDirtyOnReinitialize !== 'undefined'
                ? props.keepDirtyOnReinitialize
                : true,
        pure: false,
    };
}

const ComposedForm = compose(
    connect(mapStateToProps),
    withRouter,
    withLocalize,
    reduxForm<any, RouteComponentProps<any>>({
        onSubmitSuccess: (result, dispatch, props) => {
            // @ts-ignore
            if (props.customOnSubmitSuccess) {
                // @ts-ignore
                props.customOnSubmitSuccess(result, dispatch, props);
            } else {
                // If form is not in modal, redirect to the upper level:
                // /services/1 => /services
                if (
                    // @ts-ignore
                    !hasSomeParentTheClass(document.getElementById(`form-${props.name}`), 'modal')
                ) {
                    if (props.location.search) {
                        const query = qs.parse(props.location.search.slice(1));

                        if (query.redirectTo) {
                            props.history.push(query.redirectTo as string);
                        }
                    } else {
                        if (props.location.pathname.split('/').length > 2) {
                            dispatch(push(getUpperLevelPathname(props.location.pathname)));
                        }
                    }
                }
            }
        },
    })
)(Form) as React.ComponentType<any>;

ComposedForm.defaultProps = {
    keepDirtyOnReinitialize: true,
    confirmOnLeave: true,

    // Set these props to false for forms inside modal!
    goBackOnSubmitSuccess: true,
    goBackOnCancel: true,
    enableReinitialize: true,
};

export default ComposedForm;
