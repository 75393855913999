import {
    createSlice
} from '@reduxjs/toolkit';
import { definitions } from '@/apitypes';
import * as actions from '@/store/actions';

interface State {
    entity: definitions['TotalPriceInformationResponse'] | null;
    loading: boolean;
    error: any;
}
const initialState: State = {
    entity: null,
    loading: false,
    error: null
}

const calcualtePriceSlice = createSlice({
    name: 'calculatePrice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(actions.calculatePriceRequest, (state, action) => {
            state.loading = true;
        });

        builder.addCase(actions.calculatePriceSuccess, (state, action) => {
            state.entity = action.payload;
        });

        builder.addCase(actions.calculatePriceFail, (state, action) => {
            state.error = action.payload;
        });
    }
});

export default calcualtePriceSlice;