import 'mdn-polyfills/Array.prototype.fill';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Array.prototype.findIndex';
import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/HTMLCanvasElement.prototype.toBlob';
import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/String.prototype.startsWith';
import 'mdn-polyfills/String.prototype.includes';

import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
// @ts-ignore
import { ThroughProvider } from 'react-through';
import { LocalizeProvider } from 'react-localize-redux';
import outdatedBrowserRework from 'outdated-browser-rework';
import App from '@/containers/App';
import { history, store } from './store';
import { format } from '@/utils/datetime';
import { getCurrentLanguageCode } from './utils/i18n';

if ('serviceWorker' in navigator) {
    // Register a service worker hosted at the root of the
    // site using the default scope.
    navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(
      registration => {
        console.log('Service worker registration succeeded:', registration);
      },
      /*catch*/ error => {
        console.error(`Service worker registration failed: ${error}`);
      }
    );
  } else {
    console.error('Service workers are not supported.');
  }

Date.prototype.toISOString = function toLocalISOString() {
    return format(this, "yyyy-MM-dd'T'HH:mm:ss");
};

outdatedBrowserRework({
    language: getCurrentLanguageCode,
});

const rootEl = document.getElementById('root');

const render = (Component: React.ComponentType) =>
    ReactDOM.render(
          <Provider store={store}>
            <LocalizeProvider store={store}>
                <ThroughProvider>
                    <ConnectedRouter history={history}>
                        <Component />
                    </ConnectedRouter>
                </ThroughProvider>
            </LocalizeProvider>
        </Provider>,
        rootEl
    );

render(App);

if (module.hot) {
    module.hot.accept('./containers/App', () => {
        const NextApp = require('./containers/App').default;
        render(NextApp);
    });
}

// Log all unhandled errors to server
window.onerror = function myErrorHandler(message, line, col, error) {
    store.dispatch({
        type: 'LOG_ERRORS_REQUEST',
        payload: {
            ExceptionName: message,
            InnerExceptionName: window.navigator.userAgent,
            ExceptionMessage: message,
            URL: window.location.href,
            StackTrace: error,
        },
    });
    return false;
};
