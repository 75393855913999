import { put, call, select, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { getCompanyId } from '@/store/reducers/newAuth';
import * as actions from '../actions';
import { apiClient, convertErrorToFormError, throwSubmissionError } from '../../utils';
import { confirmSaga } from './confirm';
import { PromoCode } from '@/types';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { omit } from 'lodash';
import { ApplicationState } from '@/store';
import qs from 'qs';
import downloadPDF from '@/utils/downloadPDF';
import Notifications from 'react-notification-system-redux';
import { getAllItems } from '@/utils/common';
import exportToXLSX from '@/utils/exportToXLSX';
import { getTranslate } from 'react-localize-redux';
import { renderCreatedDate } from '@/utils/rebateColumnHelpers';

export function* promoCodes({ payload }: AnyAction) {
    try {
        const CompanyId: RootState['company']['data']['Id'] = yield select(getCompanyId);
        
        const cleanedPayload = omit(payload, 'activeTabIndex', 'OrderByDesc');

        const params = {
            CompanyId,
            IncludeConnectedServices: true,
            IncludeConnectedDaysOfWeek: true,
            CompanyRebateCodes: true,
            IncludeUsages: true,
            ...cleanedPayload,
        };

        const response: AxiosResponse = yield apiClient.get('/rebatecodes', {
            // We omit Active param because of API doesn't respond to false as it should
            params: !payload.Active ? omit(params, ['Active']) : params,
        });

        yield put(
            actions.FETCH_PROMO_CODES.success({
                ...response.data,
                Results: response.data.Results.map((result: PromoCode) => ({
                    ...result,
                    DaysOfWeek: result.DaysOfWeek.map((day: any) => day.Id),
                })),
            })
        );
    } catch (error) {
        yield put(actions.FETCH_PROMO_CODES.failure(error));
    }
}

export function* createPromoCode(action: AnyAction) {
    const create = actions.CREATE_PROMO_CODE;
    try {
        const response: AxiosResponse = yield apiClient.post(`/rebatecodes`, action.payload);

        yield put(create.success(response));
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editPromoCode(action: AnyAction) {
    const edit = actions.EDIT_PROMO_CODE;
    try {
        const location: {[key in string]: string} = yield select((s: ApplicationState) => s.router.location)
        const response: AxiosResponse = yield apiClient.put(`/rebatecodes/${action.payload.Id}`, action.payload);

        const query = qs.parse(location.search.slice(1));
        
        const { afterSuccess, ...rest } = query;
        if (query.afterSuccess) {
            yield put(push({
                pathname: query.afterSuccess as string,
                search: qs.stringify(rest)
            }))
        } else {
            yield put(edit.success(response));
        }

    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deletePromoCode({ payload, meta = {} }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    const location: {[key in string]: string} = yield select((s: ApplicationState) => s.router.location)
    if (confirmed) {
        try {
            let params: {
                ForceDelete?: boolean;
            } = {}

            if(payload.StatusName === 'Off') {
                params['ForceDelete'] = true;
            }

            const response: AxiosResponse = yield apiClient.delete(`/rebatecodes/${payload.Id}`, {
                data: payload,
                params
            });

            yield put(actions.DELETE_PROMO_CODE.success(response));

            const query = qs.parse(location.search.slice(1));
        
            const { afterSuccess, ...rest } = query;
            if (query.afterSuccess) {
                yield put(push({
                    pathname: query.afterSuccess as string,
                    search: qs.stringify(rest)
                }))
            } else if(meta.redirectOnSuccess) {
                yield put(push('/promo-codes'));
            } else {
                yield put(actions.DELETE_PROMO_CODE.success(response));
            }
        } catch (error) {
            yield put(actions.DELETE_PROMO_CODE.failure(throwSubmissionError(error)));
            const _error = throwSubmissionError(error);
            
            yield put(
                Notifications.show(
                    {
                        // @ts-ignore
                        title: _error?.errors?._error.message,
                    },
                    'error'
                )
            );
        }
    }
}

export function* addTransaction(action: AnyAction) {
    const addTransaction = actions.ADD_TRANSACTION;

    try {
        const response: AxiosResponse = yield apiClient.post(`/rebatecodes/transactions`, action.payload);
        
        // yield call(promoCodes, action.payload);
        yield put(actions.FETCH_PROMO_CODES_TRANSACTIONS.request(action.payload));
        yield put(addTransaction.success(response));

    } catch (error) {
        yield put(addTransaction.failure(throwSubmissionError(error)));
    }
}

export function* printPromocodeReceipt({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(
            `/rebatecodes/${payload.rebateCodeId}/reports?SendReceiptMethod=2`,
            { responseType: 'blob' }
        );

        downloadPDF(response.data);

        yield put(actions.PRINT_PROMOCODE_RECEIPT.success());
    } catch (error: any) {
        yield put(Notifications.show({
            title: error.message
        }, 'error'));

        yield put(actions.PRINT_PROMOCODE_RECEIPT.failure(error));
    }
}

export function* sendPromocodeReceipt({ payload }: AnyAction) {
    try {
        yield apiClient.get(
            `/rebatecodes/${payload.rebateCodeId}/reports?SendReceiptMethod=1`,
            { responseType: 'blob' }
        );

        yield put(actions.SEND_PROMOCODE_RECEIPT.success());
    } catch (error: any) {
        // TODO: use standard error on API side
        yield put(
            Notifications.show(
                {
                    // @ts-ignore
                    title: error.response.statusText
                },
                'error'
            )
        );

        yield put(actions.SEND_PROMOCODE_RECEIPT.failure(error));
    }
}

export function* promoCodesTransactions ({ payload, meta }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(
            '/rebatecodes/transactions',
            {
                params: {
                    ...(payload?.CustomerId ? {CustomerId: payload.CustomerId} : {}),
                    IncludeCustomerInformation: true,
                    IncludeServiceInformation: true,
                    Take: payload.Take,
                    Offset: payload.Offset
                }
            }
        );

        if(meta && meta.toXLSX && meta.columns) {
            yield(put(actions.EXPORT_PROMO_CODES.request()));
            response.config.params.Take = 300;
            const allResults: any[] = yield getAllItems(response);
            yield exportToXLSX(response.data && allResults, meta.columns);
            yield(put(actions.EXPORT_PROMO_CODES.success()));
        }
        yield put(actions.FETCH_PROMO_CODES_TRANSACTIONS.success(response.data));
        
    } catch (error) {
        yield put(actions.FETCH_PROMO_CODES_TRANSACTIONS.failure(error));
        yield(put(actions.EXPORT_PROMO_CODES.failure(error)));
    }
}


// TODO: add send in similar fashion like printPromocodeReceipt

export default function* promoCodesSaga() {
    yield all([
        takeLatest(actions.FETCH_PROMO_CODES.REQUEST, promoCodes),
        takeLatest(actions.CREATE_PROMO_CODE.REQUEST, createPromoCode),
        takeLatest(actions.DELETE_PROMO_CODE.REQUEST, deletePromoCode),
        takeLatest(actions.EDIT_PROMO_CODE.REQUEST, editPromoCode),
        takeLatest(actions.ADD_TRANSACTION.REQUEST, addTransaction),
        takeLatest(actions.PRINT_PROMOCODE_RECEIPT.REQUEST, printPromocodeReceipt),
        takeLatest(actions.SEND_PROMOCODE_RECEIPT.REQUEST, sendPromocodeReceipt),
        takeLatest(actions.FETCH_PROMO_CODES_TRANSACTIONS.REQUEST, promoCodesTransactions),
    ]);
}
