import get from 'lodash/get';
import { toDate } from '@/utils/datetime';

interface Column {
    name?: string;
    attribute?: string;
    type?: string;
    renderer?: (row: any) => any;
}

// Converts configs that we pass to DataTable to convenient for exporting to xlsx format
export function getCSVConfig(data: any[] = [], columnsConfig: Column[] = []): any[] {
    return data.map((row) =>
        columnsConfig.map((column: Column) => {
            if (column.attribute) {
                const value = get(row, column.attribute);

                if (column.type === 'date' && value) {
                    return toDate(value);
                }
                return value;
            }
            if (column.renderer) {
                return column.renderer(row);
            }
            return null;
        })
    );
}

export function downloadFile(workbook: Object) {
    // @ts-ignore
    workbook.outputAsync().then(function (blob) {
        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // @ts-ignore
            // If IE, you must use a different method.
            window.navigator.msSaveOrOpenBlob(blob, 'out.xlsx');
        } else {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = 'out.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
        console.timeEnd('Export to XLSX');
    });
}

// Works only with one sheet
export default function exportToXLSX(data: any[] = [], columnsConfig: Column[] = []): void {
    try {
        console.time('Export to XLSX');
        const preraredData = getCSVConfig(data, columnsConfig);

        // @ts-ignore
        return import('xlsx-populate/browser/xlsx-populate-no-encryption').then(
            ({ default: XlsxPopulate }) => {
                // @ts-ignore
                return XlsxPopulate.fromBlankAsync().then((workbook) => {
                    // Populate header
                    workbook
                        .sheet(0)
                        .cell('A1')
                        // @ts-ignore
                        .value([columnsConfig.map((column) => column.name)]);

                    // Populate body
                    workbook
                        .sheet(0)
                        .cell('A2')
                        // @ts-ignore
                        .value(preraredData);

                    columnsConfig.forEach((column, index) => {
                        if (column.type === 'date') {
                            workbook
                                .sheet(0)
                                .column(index + 1)
                                .style('numberFormat', 'yyyy mmmm dd, HH:mm');
                        }
                    });

                    downloadFile(workbook);
                });
            }
        );
    } catch (e) {
        console.error(e);
    }
}
